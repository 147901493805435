<template>
	<v-sheet
		class="evidence mb-4 pb-4"
		:style="'border-top: 5px solid ' + $listUtils.entityStatus[task.status].color"
		elevation="1"
		style="overflow: hidden;"
	>
		<v-card
			elevation="0"
			height="30"
			width="100%"
			color="transparent"
			class="px-2 pt-2 absolute top"
		>
			<v-tooltip
				top
				v-if="task.dueDate && task.status != 3 && $moment(task.dueDate.toDate()).isBefore($moment(), 'minutes')"
			>
				<template v-slot:activator="{ on, attrs }">
					<div
						v-bind="attrs"
						v-on="on"
						class="pa-0 ma-0"
					>
						<v-icon
							small
							class="iconOverflow pr-0 pb-3"
							:color="task.dueDate && task.status != 3 && $moment(task.dueDate.toDate()).isBefore($moment(), 'minutes') ? 'error' : ''"
							@click="dialog = true"
						>mdi-alert-circle-outline
						</v-icon>
					</div>
				</template>

				<!-- If Retard -->
				<v-card
					color="transparent"
					dark
					v-if="task.dueDate && task.status != 3 && $moment(task.dueDate.toDate()).isBefore($moment(), 'minutes')"
				>
					<span v-if="Math.round(Math.abs($moment.duration($moment(task.dueDate.toDate()).diff($moment())).asHours())) == 0">En
						retard de
						{{ Math.round(Math.abs($moment.duration($moment(task.dueDate.toDate()).diff($moment())).asMinutes())) }}
						minutes</span>
					<span v-else-if="Math.round($moment.duration($moment(task.dueDate.toDate()).diff($moment())).asDays()) == 0">En
						retard de
						{{ Math.round(Math.abs($moment.duration($moment(task.dueDate.toDate()).diff($moment())).asHours())) }}
						heures</span>
					<span v-else>En retard de
						{{ Math.round(Math.abs($moment.duration($moment(task.dueDate.toDate()).diff($moment())).asDays())) }}
						jours.</span>
				</v-card>

				<!-- If Pas loin de la deadline -->
				<v-card
					color="transparent"
					dark
					v-else-if="task.dueDate && task.status != 3 && $moment(task.dueDate.toDate()).isSame($moment(), 'day')"
				>
					<span v-if="Math.round($moment.duration($moment(task.dueDate.toDate()).diff($moment())).asHours()) > 1">{{ Math.round($moment.duration($moment(task.dueDate.toDate()).diff($moment())).asHours()) }}
						heures restantes pour effectuer cette tâche.</span>
					<span v-else-if="Math.floor($moment.duration($moment(task.dueDate.toDate()).diff($moment())).asHours()) == 1">1
						heure restante pour effectuer cette tâche.</span>
					<span v-else>{{ Math.round($moment.duration($moment(task.dueDate.toDate()).diff($moment())).asMinutes()) }}
						minutes restantes pour effectuer cette tâche.</span>
				</v-card>
			</v-tooltip>

			<v-tooltip top>
				<template v-slot:activator="{ on, attrs }">
					<div
						v-bind="attrs"
						v-on="on"
					>
						<v-icon
							small
							style="position: absolute; top: 10px; right: 8px;"
							@click="dialog = true"
						>mdi-login</v-icon>
					</div>
				</template>
				<p class="my-0">
					Priorité : {{ $listUtils.priority[task.priority] }}
				</p>
				<p
					v-if="task.dueDate"
					class="my-0"
				>
					Deadline : {{ $functions.getDateFormat(task.dueDate) }}
				</p>
			</v-tooltip>
		</v-card>
		<Title
			:titleColor="task.dueDate && task.status != 3 && $moment(task.dueDate.toDate()).isBefore($moment(), 'minutes') ? 'error' : ''"
			weight="regular"
			titleSize=""
			class="ma-0 mb-6 pl-4 pt-2 pr-8"
		>{{ task.task }}</Title>
		<v-sheet
			:color="$listUtils.entityStatus[task.status].color"
			height="1"
			class="mx-4"
		>
		</v-sheet>
		<v-tooltip
			top
			max-width="450"
		>
			<template v-slot:activator="{ on, attrs }">
				<div
					v-bind="attrs"
					v-on="on"
				>
					<v-card
						elevation="0"
						class="px-4"
					>
						<p
							class="subtitle-2 oneLine mt-2"
							v-html="task.content"
						>
						</p>
					</v-card>
				</div>
			</template>
			<p v-html="task.content"></p>
		</v-tooltip>
		<v-card
			height="120px"
			class="px-4"
			elevation="0"
		>
			<p class="my-0 py-0 subtitle-2">
				Priorité : {{ $listUtils.priority[task.priority] }}
			</p>
			<p
				v-if="task.dueDate"
				class="my-0 py-0 subtitle-2"
			>
				Deadline : {{ $functions.getDateFormat(task.dueDate) }}
			</p>

			<Participants
				title
				:participantsData="task.participantsData"
			/>

		</v-card>

		<v-dialog
			v-model="dialog"
			transition="dialog-bottom-transition"
			max-width="960"
		>
			<v-card
				class="glassmorphism"
				color="transparent"
			>
				<ButtonTrigger
					:title="task.task"
					titleSize="h6"
					small
					whiteText
					:noButton="$vuetify.breakpoint.smAndUp"
					icon
					content=""
					@click.native="dialog = false"
				/>
				<v-col cols="12">
					<v-card :style="'border-top: 4px solid ' + $listUtils.entityStatus[task.status].color">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-row>

											<v-col cols="12">
												<h2>
													{{task.task}}
												</h2>
											</v-col>

											<v-col cols="12">
												<p v-html="task.content">
												</p>
											</v-col>

											<v-sheet
												height="1"
												color="grey"
												width="80%"
												class="mx-auto mb-4"
											></v-sheet>

											<v-col cols="12">
												<p class="my-0">
													Priorité : {{ $listUtils.priority[task.priority] }}
												</p>
												<p
													v-if="task.dueDate"
													class="my-0"
												>
													Deadline : {{ $functions.getDateFormat(task.dueDate) }}
												</p>
											</v-col>
											<v-col cols="12">

												<Participants
													title
													:participantsData="task.participantsData"
												/>

											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
			</v-card>
		</v-dialog>

	</v-sheet>
</template>

<style scoped>
	.v-card {
		cursor: pointer;
	}

	::selection {
		background-color: transparent;
	}
</style>

<script>
	import Title from '@/components/graphics/title';
	import ButtonTrigger from '@/components/graphics/buttonTrigger'
	import Participants from '@/components/modules/#global/Participants'

	export default {
		components: {
			Title,
			ButtonTrigger,
			Participants
		},
		props: {
			task: {
				type: Object,
				default: () => ({})
			}
		},
		data() {
			return {
				dialog: false,
			};
		},
	};
</script>