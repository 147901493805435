<template>

	<v-row class="row pt-16 px-4">

		<v-container class="row">

			<v-card
				elevation="0"
				style="overflow: scroll;"
				class="col-3"
				v-for="(type, index) in taskTypeDictionnary"
				:key="`head_${type}`"
			>

				<v-card elevation="0">
					<span style="font-size: clamp(1rem, -1.5rem + 26px, 3rem);">{{ $listUtils.elementStatus[index] }} ({{ tasks[type].length }})</span>
				</v-card>

			</v-card>

			<v-card
				elevation="1"
				height="60vh"
				style="overflow: scroll;"
				class="col-3"
				color="transparent"
				v-for="(type) in taskTypeDictionnary"
				:key="`head_${type}`"
			>

				<draggable
					class="list-group"
					:list="tasks[type]"
					group="people"
					@change="log"
				>

					<v-card
						elevation="0"
						color="transparent"
						v-for="task in tasks[type]"
						:key="task.id"
					>
						<TaskCard :task="task"></TaskCard>
					</v-card>

				</draggable>

			</v-card>

		</v-container>

	</v-row>

</template>

<script>
	import draggable from "vuedraggable";
	import TaskCard from "@/components/modules/@project/kanban/Card.vue";


	export default {
		name: "KanbanComponent",
		components: {
			TaskCard,
			draggable
		},
		props: {
			project: {
				type: String,
				default: null
			},
			company: {
				type: String,
				default: null
			}
		},
		data: () => ({
			list1: [{
					name: "John",
					id: 1
				},
				{
					name: "Joao",
					id: 2
				},
				{
					name: "Jean",
					id: 3
				},
				{
					name: "Gerard",
					id: 4
				}
			],
			list2: [{
					name: "Juan",
					id: 5
				},

				{
					name: "Edgard",
					id: 6
				},
				{
					name: "Johnson",
					id: 7
				}
			],
			tasks: {
				unsupported: [],
				waiting: [],
				inprogress: [],
				done: [],

			},

			taskTypeDictionnary: [
				"unsupported",
				"waiting",
				"inprogress",
				"done"
			]
		}),
		methods: {
			add: function() {
				this.list.push({
					name: "Juan"
				});
			},
			replace: function() {
				this.list = [{
					name: "Edgard"
				}];
			},
			clone: function(el) {
				return {
					name: el.name + " cloned"
				};
			},
			log: function(evt) {
				let id;
				if (evt.added) id = evt.added.element.id;
				if (evt.removed) return;
				if (evt.moved) id = evt.moved.element.id;

				if (id != null) {
					this.updateTask(id);
				}
			},
			updateTask(id) {
				this.taskTypeDictionnary.forEach((type) => {
					let t = this.$functions.findWithAttr(
						this.tasks[type],
						"id",
						id
					);

					t = t--;

					if (t > -1) {
						this.tasks[type][t].status = this.taskTypeDictionnary.indexOf(type);

						let newTask = {
							...this.tasks[type][t],
							'lastUpdate': new Date()
						};
						delete newTask.id;
						delete newTask.participantsData;
						delete newTask.projectData;

						this.$db.collection("companies").doc(this.company).collection("tasks").doc(id).set(
							newTask, {
								merge: true
							});
					}
				});
			},
			init(ref) {
				ref.onSnapshot((res) => {

					function pushToCustomer(r, tasks, document, addedData = null, whatData = null) {
						var returnData = {
							...document.data(),
							id: document.id
						};

						if (addedData) returnData[whatData] = addedData;

						if (r == -1) {
							if (!returnData.lastUpdate) returnData.lastUpdate = returnData.creationDate;
							tasks.push(returnData)
						}

						if (tasks[0].status != 3) tasks = tasks.sort(function(a, b) {
							return b.priority - a.priority
						});
						else tasks = tasks.sort(function(a, b) {
							return b.lastUpdate - a.lastUpdate
						});

						return tasks;
					}

					res.forEach((document) => {
						if (document.ref.path && !document.ref.path.includes(this.company)) return;

						var data = document.data();

						if (data.participants && data.participants.length) {
							var participantsData = [];

							data.participants.forEach((item) => {
								this.$db.collection("companies").doc(this.company).collection("customers").doc(item).get().then((doc) => {
									if (doc.data()) {
										participantsData.push({
											...doc.data(),
											id: doc.id
										});
									} else {
										this.$db.collection("companies").doc(this.company).collection("companies").doc(item).get().then((doc) => {
											if (doc.data()) {
												participantsData.push({
													...doc.data(),
													id: doc.id
												});
											} else {
												this.$db.collection("users").doc(item).get().then((doc) => {
													participantsData.push({
														...doc.data(),
														id: doc.id,
														report: false
													});
												});
											}
										});
									}
								});

								switch (document.data().status) {
									case 0:
										pushToCustomer(
											this.$functions.findWithAttr(
												this.tasks.unsupported,
												"id",
												document.id
											),
											this.tasks.unsupported,
											document,
											participantsData,
											"participantsData"
										);
										break;
									case 1:
										pushToCustomer(
											this.$functions.findWithAttr(
												this.tasks.waiting,
												"id",
												document.id
											),
											this.tasks.waiting,
											document,
											participantsData,
											"participantsData"
										);
										break;
									case 2:
										pushToCustomer(
											this.$functions.findWithAttr(
												this.tasks.inprogress,
												"id",
												document.id
											),
											this.tasks.inprogress,
											document,
											participantsData,
											"participantsData"
										);
										break;
									case 3:
										pushToCustomer(
											this.$functions.findWithAttr(
												this.tasks.done,
												"id",
												document.id
											),
											this.tasks.done,
											document,
											participantsData,
											"participantsData"
										);
										break;
								}
							})
						} else {
							switch (document.data().status) {
								case 0:
									pushToCustomer(
										this.$functions.findWithAttr(
											this.tasks.unsupported,
											"id",
											document.id
										),
										this.tasks.unsupported,
										document
									);
									break;
								case 1:
									pushToCustomer(
										this.$functions.findWithAttr(
											this.tasks.waiting,
											"id",
											document.id
										),
										this.tasks.waiting,
										document
									);
									break;
								case 2:
									pushToCustomer(
										this.$functions.findWithAttr(
											this.tasks.inprogress,
											"id",
											document.id
										),
										this.tasks.inprogress,
										document
									);
									break;
								case 3:
									pushToCustomer(
										this.$functions.findWithAttr(
											this.tasks.done,
											"id",
											document.id
										),
										this.tasks.done,
										document
									);
									break;
							}
						}

						/*if(document.data().project) {
							this.$db.collection("companies").doc(this.company).collection("projects").doc(document.data().project).get().then((doc) => {
								var index = this.$functions.findWithAttr(
									tempTasks,
									"id",
									document.id
								);

								tempTasks[index].projectData = {
									...doc.data(),
									id: doc.id
								};
							});
						}*/
					});
				});
			}
		},
		computed: {
			dragOptions() {
				return {
					animation: 200,
					group: "description",
					disabled: false,
					ghostClass: "ghost"
				};
			}
		},
		async created() {
			setTimeout(() => {
				if (this.project != null) {
					this.init(this.$db.collection("companies").doc(this.company).collection("tasks").where(
						"project", "==", this.project));
				} else {
					this.init(this.$db.collection("companies").doc(this.company).collection("tasks"));
				}
			}, 500);
		}

	}
</script>

<style scoped>
	.ghost {
		opacity: 0.5;
		background: #c8ebfb;
	}

	.list-group {
		min-height: 50vh;
	}

	.list-group-item {
		cursor: move;
	}

	.list-group-item i {
		cursor: pointer;
	}
</style>