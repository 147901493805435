<template>
	<div>

		<Head :title="`Projet ${this.projectInfo.title} - ${this.projectInfo.type}`" offset="15vh" />

		<v-container class="maxWidth">
			<v-row>

				<v-col cols="12">

					<InfoProject :project="projectInfo" class="mb-5"></InfoProject>

					<v-row>
						<v-col cols="12">
							<v-card dark class="pa-4 glassmorphism" color="transparent">

								<v-btn icon @click="dialogs.modify = true" class="float-left">
									<v-icon>mdi-cog</v-icon>
								</v-btn>

								<h2 class="font-weight-regular pt-1">Gestion du Projet</h2>

							</v-card>
						</v-col>
					</v-row>

				</v-col>

				<v-col cols="12">
					<v-card class="mb-5">
						<v-card-title>
							<h2 class="font-weight-regular">Accès rapide</h2>
						</v-card-title>

						<v-card-text>
							<v-list>
								<v-list-item-group v-model="model">
									<v-list-item v-for="item in integrations" :key="item.id"
										@click="$functions.goTo(item.link)">
										<v-list-item-content>
											<v-list-item-title>{{ item.name }}</v-list-item-title>
											<v-list-item-subtitle>{{ item.link }}</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-card-text>
					</v-card>

					<v-card class="mb-5" v-if="customers > 0">
						<v-card-title>Client<span v-if="customers.length > 1">s</span></v-card-title>

						<v-card-text>
							<v-list disabled>
								<v-list-item-group>
									<v-list-item v-for="item in customers" :key="item.id" align="center">
										<v-list-item-icon>
											<v-avatar size="42"
												:style="'background-color: #' + $functions.convertToHex(item.email) + ';'">
												<span v-if="!item.avatar && item.firstName"
													class="font-weight-regular white--text">
													{{ item.firstName[0] }}{{ item.lastName[0] }}
												</span>
												<span v-else-if="!item.avatar && item.name"
													class="font-weight-regular white--text">
													{{ item.name[0] }}
												</span>
												<v-img v-else :src="$functions.getImgLink(item.avatar, 50)"></v-img>
											</v-avatar>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title v-if="item.firstName">{{ item.firstName }}
												{{ item.lastName }}</v-list-item-title>
											<v-list-item-title v-else>{{ item.name }}</v-list-item-title>
											<v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-list>
						</v-card-text>
					</v-card>

				</v-col>

				<v-col cols="12">
					<Kanban v-if="userInfo.company" :company="userInfo.company" :project="projectInfo.id"></Kanban>
				</v-col>

			</v-row>
		</v-container>

		<v-dialog v-model="dialogs.modify" max-width="960">

			<h1 class="font-weight-regular ma-4 white--text">{{ projectInfo.title }}</h1>

			<v-col cols="12">
				<v-card>

					<v-card-title>
						<h2 class="font-weight-regular">Status</h2>
					</v-card-title>

					<v-card-text>
						<v-btn-toggle v-model="projectStatus" :color="projectStatus.color" group mandatory>
							<v-btn v-for="status in $listUtils.entityStatus" :key="status.label" :value="status"
								@click="changeStatus(status)" class="neon">
								{{ status.label }}
							</v-btn>
						</v-btn-toggle>
					</v-card-text>

				</v-card>
			</v-col>



			<v-col cols="12">

				<v-card>
					<v-card-title>
						<h2 class="font-weight-regular">Informations</h2>
					</v-card-title>

					<v-card-text>
						<v-row>

							<v-col cols="12" sm="6">
								<v-text-field v-model="projectInfo.title" label="Titre"></v-text-field>
								<v-text-field v-model="projectInfo.value" label="Valeur" type="number"></v-text-field>
								<ImageUpload v-if="userInfo.company" :companyID="userInfo.company" type="project" @newdata="projectInfo.avatar=$event" />
							</v-col>

							<v-col cols="12" sm="6">
								<v-select v-model="projectInfo.type" :items="this.$listUtils.projectCategory"
									label="Type">
								</v-select>

								<v-autocomplete clearable v-model="projectInfo.customers" :items="people" chips
									deletable-chips color="blue-grey lighten-2" label="Client" item-text="id"
									item-value="id" multiple>
									<template v-slot:selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select"
											close @click:close="remove(data.item)">
											<v-avatar left>
												<v-img contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
											</v-avatar>
											{{ data.item.name }}
										</v-chip>
									</template>
									<template v-slot:item="data">
										<template v-if="data.header">
											<v-list-item-content v-text="data.item"></v-list-item-content>
										</template>
										<template v-else>
											<v-list-item-avatar>
												<v-img contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
											</v-list-item-avatar>
											<v-list-item-content>
												<!-- v-html="data.item.name" -->
												<v-list-item-title>{{ data.item.name }}</v-list-item-title>
												<v-list-item-subtitle
													v-html="'Numéro ' + data.item.denom + ' : ' + data.item.content">
												</v-list-item-subtitle>
											</v-list-item-content>
										</template>
									</template>
								</v-autocomplete>

							</v-col>

							<v-col cols="12">
								<v-textarea v-model="projectInfo.content" label="Insérez ici la description du projet">
								</v-textarea>
							</v-col>
						</v-row>
					</v-card-text>

				</v-card>

			</v-col>

			<v-col cols="12">
				<v-card>
					<v-card-title>
						<h2 class="font-weight-regular">Intégrations</h2>
					</v-card-title>
					<v-card>
						<v-card-title class="headline">
							Ajouter une intégration
						</v-card-title>
						<v-card-text>
							<v-container>
								<v-row class="mb-8">
									<v-col cols="12">
										<v-text-field label="Nom" :rules="$fieldsRules.required('nom')"
											v-model="integration.name"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field label="Lien" :rules="$fieldsRules.required('lien')"
											v-model="integration.link" prefix="https://"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-btn class="float-right neon" width="200" color="success" outlined
											:block="$vuetify.breakpoint.xsOnly" @click="addIntegration()">
											Ajouter
										</v-btn>
									</v-col>
								</v-row>


								<v-col cols="12" v-for="item in integrations" :key="item.id">

									<v-row>

										<v-col cols="10">
											<v-btn class="neon" block outlined @click="$functions.goTo(item.link)">
												{{ item.name }} </v-btn>
										</v-col>

										<v-col cols="2">
											<v-btn class="neon" block outlined color="error"
												@click="removeIntegration(item.id)">
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</v-col>

									</v-row>
								</v-col>
							</v-container>

						</v-card-text>

					</v-card>

				</v-card>

			</v-col>

			<v-col cols="12">
				<v-card>
					<v-card-actions>
						<v-btn text @click="dialogs.delete = true" color="error" class="neon">Supprimer le projet
						</v-btn>
						<v-spacer></v-spacer>

						<v-btn color="grey" text @click="dialogs.modify = false">
							Annuler
						</v-btn>
						<v-btn color="success" class="neon" width="200" outlined text
							@click="modifyProject(), dialogs.modify = false">
							Modifier
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>


		</v-dialog>

		<v-dialog v-model="dialogs.delete" max-width="600">
			<v-card class="pt-1" color="error" elevation="12">
				<v-card>
					<v-card-title class="headline mb-4">
						Voulez-vous supprimer ce projet ?
					</v-card-title>

					<v-card-text>
						<p>Après avoir cliqué sur "Confirmer la suppression", le projet "{{ projectInfo.title }}" sera
							définitivement supprimé.</p>
						<p style="text-decoration: underline;">Cette action est irréversible.</p>
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="error" text @click="removeProject()">
							Confirmer la suppression
						</v-btn>
						<v-btn color="primary" text @click="dialogs.delete = false">
							Annuler
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-card>
		</v-dialog>

	</div>
</template>

<script>
	import Head from '@/components/graphics/head';
	import InfoProject from '@/components/modules/@project/InfoProject';
	import ImageUpload from '@/components/graphics/ImageUpload';

	import Kanban from "@/components/modules/@project/kanban/Main.vue";

	export default {
		head: {
			title: {
				inner: "Tableau de Bord"
			}
		},
		components: {
			Head,
			InfoProject,
			Kanban,
			ImageUpload
		},
		props: {
			'id': {
				type: String
			}
		},
		data() {
			return {
				userInfo: this.$models.user,
				projectInfo: this.$models.project,

				model: '',

				customers: [],

				integration: {},
				integrations: [],

				tasks: [],

				dialog: {
					delete: false,
					delete2: false,
					integrations: false
				},

				projectStatus: {
					color: 'primary'
				},

				dialogs: {
					delete: false,
					modify: false
				},

				people: []
			}
		},
		methods: {
			addIntegration() {
				this.$db.collection("companies").doc(this.userInfo.company).collection("projects").doc(this.id).collection(
					"integrations").add({
						...this.integration,
						link: "https://" + this.integration.link
					}).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: "L'intégration à été ajoutée.",
						color: "success"
					});

					this.integration = {};
				})
			},
			removeIntegration(idIntegration) {
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("projects").doc(this.id).collection("integrations").doc(idIntegration), "L'intégration à été supprimée.");
			},
			changeStatus(attr) {
				this.$db.collection("companies").doc(this.userInfo.company).collection("projects").doc(this.id).set({
					status: this.$listUtils.entityStatus.indexOf(attr)
				}, {
					merge: true
				});
			},
			removeProject() {
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("projects").doc(this.id), `Le projet ${this.projectInfo.title} a bien été supprimé.`);

				this.$router.push('/projects');
			},
			modifyProject() {
				this.$db.collection("companies").doc(this.userInfo.company).collection("projects").doc(this.id).set(this.projectInfo, {
					merge: true
				}).then(() => {
					this.$store.dispatch('setSnackbar', {
						text: `Le projet ${this.projectInfo.title} a été modifié.`,
					});
				});
			}
		},

		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.$db.collection("companies").doc(this.userInfo.company).collection("projects").doc(this.id)
					.onSnapshot(doc => {
						this.projectInfo = {
							...doc.data(),
							id: doc.id
						};

						this.projectStatus = this.$listUtils.entityStatus[this.projectInfo.status];

						this.customers = [];

						if (this.projectInfo.customers && this.projectInfo.customers.length > 0) this.projectInfo.customers.forEach((customer) => {
							this.$db.collection("companies").doc(this.userInfo.company).collection("customers").doc(customer).get().then(doco => {
								if (!doco.data()) {
									this.$db.collection("companies").doc(this.userInfo.company).collection("companies").doc(customer).get().then(doco => {
										this.customers.push({
											...doco.data(),
											id: doco.id
										});
									});
								} else {
									this.customers.push({
										...doco.data(),
										id: doco.id
									});
								}
							});
						});

						document.title =`Projet ${this.projectInfo.title} - ${this.projectInfo.type} — Katana`;

						this.$db.collection("companies").doc(this.userInfo.company).collection("projects").doc(this.id).collection("integrations").onSnapshot(ref => {
							let tempIntegrations = [];

							ref.forEach(document => {
								tempIntegrations.push({
									...document.data(),
									id: document.id
								});
							});

							this.integrations = tempIntegrations;
						});

						this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
							this.people = [{
								header: 'Clients'
							}]

							this.$db.collection("companies").doc(this.userInfo.company).collection("customers").onSnapshot((res) => {
								res.forEach((document) => {
									this.people.push({
										'name': document.data().lastName
											.toUpperCase() + " " + document
											.data().firstName,
										'content': document.data().nClient,
										'id': document.id,
										'avatar': document.data().avatar,
										'denom': "client"
									});
								});

								this.people.push({
									divider: true
								}, {
									header: 'Entreprises'
								})

								this.$db.collection("companies").doc(doc.data().company).collection("companies").onSnapshot((res) => {
									res.forEach((document) => {
										this.people.push({
											'name': document.data()
												.name,
											'content': document.data()
												.nCompany,
											'id': document.id,
											'avatar': document.data()
												.avatar,
											'denom': "entreprise"
										});
									});
								});
							});
						});
					});
			});
		}
	}

</script>
