<template>
	<div>
		<v-card class="pa-5 glassmorphism" dark color="transparent">
			<v-row>
				<v-col cols="12" sm="10">
					<h1 class="display-1 mb-4">
						{{ project.title }}
						<v-tooltip right :color="$cssGlobal.getStatus($listUtils.elementStatus[project.status])">
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-bind="attrs" v-on="on" :color="$cssGlobal.getStatus($listUtils.elementStatus[project.status])" fab dark height=" 15px" width="15px"></v-btn>
							</template>
							<span>{{ $listUtils.elementStatus[project.status] }}</span>
						</v-tooltip>
					</h1>
					<p class="subtitle-2">
						<span>
							<v-icon small>mdi-alpha-p-circle-outline</v-icon> {{ project.type }}
						</span>
						<span v-if="project.creationDate">
							・ <v-icon small v-if="project.creationDate">mdi-clock-outline</v-icon> Ajouté à Katana le {{ $functions.getDateFormat(project.creationDate, "DD/MM/YYYY") }}
						</span>
						<template v-if="project.due">
							・ 
							<template v-if="project.due && project.status != 3 && $moment(project.due.toDate()).isBefore($moment(), 'minutes')">
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<span class="error--text" v-bind="attrs" v-on="on">
											<v-icon small color="error">mdi-clock-alert-outline</v-icon> A terminer pour le {{ $functions.getDateFormat(project.due, "DD/MM/YYYY") }}
										</span>
									</template>
									<span v-if="Math.round(Math.abs($moment.duration($moment(project.due.toDate()).diff($moment())).asHours())) == 0">En
										retard de
										{{ Math.round(Math.abs($moment.duration($moment(project.due.toDate()).diff($moment())).asMinutes())) }}
										minutes</span>
									<span v-else-if="Math.round($moment.duration($moment(project.due.toDate()).diff($moment())).asDays()) == 0">En
										retard de
										{{ Math.round(Math.abs($moment.duration($moment(project.due.toDate()).diff($moment())).asHours())) }}
										heures</span>
									<span v-else>En retard de
										{{ Math.round(Math.abs($moment.duration($moment(project.due.toDate()).diff($moment())).asDays())) }}
										jours.</span>
								</v-tooltip>
							</template>
							<template v-else-if="project.due && project.status != 3 && $moment(project.due.toDate()).isSame($moment(), 'day')" class="warning--text">
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<span class="warning--text" v-bind="attrs" v-on="on">
											<v-icon small color="warning">mdi-clock-check-outline</v-icon> A terminer pour le {{ $functions.getDateFormat(project.due, "DD/MM/YYYY") }}
										</span>
									</template>
									<span v-if="Math.round($moment.duration($moment(project.due.toDate()).diff($moment())).asHours()) > 1">{{ Math.round($moment.duration($moment(project.due.toDate()).diff($moment())).asHours()) }}
										heures restantes pour effectuer cette tâche.</span>
									<span v-else-if="Math.floor($moment.duration($moment(project.due.toDate()).diff($moment())).asHours()) == 1">1
										heure restante pour effectuer cette tâche.</span>
									<span v-else>{{ Math.round($moment.duration($moment(project.due.toDate()).diff($moment())).asMinutes()) }}
										minutes restantes pour effectuer cette tâche.</span>
								</v-tooltip>
							</template>
							<template v-else-if="project.due && project.status == 3">
								<span>
									<v-icon small>mdi-clock-check-outline</v-icon> Terminé le {{ $functions.getDateFormat(project.due, "DD/MM/YYYY") }}
								</span>
							</template>
							<template v-else>
								<span>
									・ <v-icon small>mdi-clock-check-outline</v-icon> A terminer pour le {{ $functions.getDateFormat(project.due, "DD/MM/YYYY") }}
								</span>
							</template>
						</template>
					</p>

					<v-row v-if="project.value">
						<v-col cols="12" class="pb-0">
							<h3 class="font-weight-light">Détails</h3>
						</v-col>
						<v-col>
							<p>Prix : {{ Number(project.value).toLocaleString(undefined, { minimumFractionDigits: 2}) }}€</p>
						</v-col>
					</v-row>

					<v-row v-if="project.content">
						<v-col cols="12" class="pb-0">
							<h3 class="font-weight-light">Description</h3>
						</v-col>
						<v-col>
							{{ project.content }}
						</v-col>
					</v-row>
				</v-col>

				<v-col cols="12" sm="2">
					<v-img v-if="project.avatar" contain :src="$functions.getImgLink(project.avatar)"></v-img>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>


<script>
	export default {
		props: {
			'project': {
				type: Object
			},
			'large': {
				type: Boolean,
				default: false
			},
			'admin': {
				type: Boolean,
				default: false
			}
		}
	}
</script>